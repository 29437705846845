import React from 'react';
import { FaDownload, FaFile } from 'react-icons/fa';
import {
  apiDownloadDocument,
  // apiDownloadOptionalDocument,
} from '../services/axios-client/axeCommons';

function UploadedFile({ doc, activeCustomerId }) {
  const downloadDocument = async (documentId) => {
    const response = await apiDownloadDocument(documentId, activeCustomerId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  // const downloadOptionalDocument = async (documentId) => {
  //   const response = await apiDownloadOptionalDocument(documentId);
  //   const alink = document.createElement('a');
  //   alink.target = '_blank';
  //   alink.href = response.data.data.contents;
  //   alink.download = response.data.data.filename;
  //   alink.click();
  // };

  return (
    <div className="col-12 p-1">
      <div className="p-3 border rounded border-2 position-relative">
        <span className="badge bg-warning mb-3 position-absolute end-0 bottom-0 me-2">
          {doc.document_type.name}
        </span>
        <div className="d-flex align-items-start justify-content-start flex-column gap-3">
          <div className="d-flex align-items-center justify-content-start gap-3">
            <FaFile className="fs-4 text-dark" />
            <p className="m-0">{doc.original_filename}</p>
          </div>
          <button
            onClick={() => downloadDocument(doc.id)}
            className="btn btn-success btn-sm px-3 py-1"
          >
            <FaDownload className="fs-6" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default UploadedFile;
