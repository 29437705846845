import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import moment from 'moment/moment';
import { useParams } from 'react-router';
import axe from '../../services/axios-client/axiosClient';
import { ModalBody, ModalFooter } from '../../pages/ClientPage.styles';
import { Uploader } from '../../common/components/uploader/Uploader';
import { generalAlertError } from '../../common/utils/alerts';

const schema = yup.object({
  file: yup.object().required('Il campo è obbligatorio'),
  note: yup.string().required('Il campo è obbligatorio'),
  date: yup.string().required('Il campo è obbligatorio'),
  active_customer_id: yup.number().required('Id Cliente e obligatoria!'),
});

const asInputOptions = {
  shouldDirty: true,
  shouldTouch: true,
  shouldValidate: true,
};

function CancellationForm({
  selectedQuote,
  closeAll,
  fetchData,
  active_customer,
}) {
  const [sendingData, setSendingData] = useState(false);
  const { requestToken, clientId } = useParams();
  const {
    register,
    formState: { errors, touchedFields },
    setValue,
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
    values: {
      file: null,
      note: '',
      date: '',
      active_customer_id: active_customer.id,
    },
  });

  const formData = watch();

  const createNewCancellation = async () => {
    setSendingData(true);
    const response = await axe.post(
      `/post-vendita/cancellation/create/${selectedQuote.id}`,
      formData,
    );

    if (response.status !== 200) {
      generalAlertError('Errore');
      console.log('Error', response);
      setSendingData(false);
      return;
    }

    fetchData(requestToken, clientId);
    closeAll();
    setSendingData(false);
  };

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }
    if (touchedFields[name] === undefined) {
      return false;
    }
    return true;
  };

  const fileInputHandler = (file) => {
    let baseURL = '';
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const fileType = file.type;
    reader.onload = () => {
      baseURL = reader.result;
      setValue(
        'file',
        {
          original_filename: file.name,
          content: baseURL.replace(`data:${fileType};base64,`, ''),
        },
        asInputOptions,
      );
    };
  };

  return (
    <>
      <ModalBody className="p-4">
        <form>
          <div className="mb-3">
            <label htmlFor="replacement_reason" className="form-label">
              Data cessazione
            </label>
            <input
              type="date"
              {...register('date')}
              className={`form-control ${isValid('date') ? 'is-valid' : ''} ${
                errors.date?.message ? 'is-invalid' : ''
              }`}
              min={moment().format('YYYY-MM-DD')}
            />
            <div className="invalid-feedback">{errors.date?.message}</div>
          </div>
          <div className="mb-3">
            <label htmlFor="nota" className="form-label">
              Descrivi motivo della cessazione
            </label>
            <textarea
              {...register('note')}
              className={`form-control ${isValid('note') ? 'is-valid' : ''} ${
                errors.note?.message ? 'is-invalid' : ''
              }`}
              rows="3"
            />
            <div className="invalid-feedback">{errors.note?.message}</div>
          </div>
          <div className="mb-3">
            <Uploader
              note="Carica documento"
              onFileUpload={(file) => fileInputHandler(file)}
            />
          </div>
        </form>
      </ModalBody>
      <ModalFooter className="p-3">
        <div className="d-flex align-items-center justify-content-end">
          <button
            disabled={sendingData}
            onClick={() => createNewCancellation()}
            className="btn btn-success px-5 py-2"
          >
            Crea
          </button>
        </div>
      </ModalFooter>
    </>
  );
}

export default CancellationForm;
