import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import PageLoading from '../common/components/elements/PageLoading';
import axe from '../services/axios-client/axiosClient';

function SignatureScreen() {
  const { request_token } = useParams();

  const [loading, setLoading] = useState(true);
  const [documents, setDocuments] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [customerEmail, setCustomerEmail] = useState('');
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [signatureSubmitted, setSignatureSubmitted] = useState(false);

  const getEventPosition = (e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    if (e.touches) {
      // Touch event
      return {
        x: e.touches[0].clientX - rect.left,
        y: e.touches[0].clientY - rect.top,
      };
    }
    // Mouse event
    return {
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    };
  };

  // Start drawing
  const startDrawing = (e) => {
    e.preventDefault();
    const { x, y } = getEventPosition(e);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    ctx.beginPath();
    ctx.moveTo(x, y);
    setIsDrawing(true);
  };

  // Continue drawing
  const draw = (e) => {
    e.preventDefault();
    if (!isDrawing) return;

    const { x, y } = getEventPosition(e);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    ctx.lineTo(x, y);
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 2;
    ctx.stroke();
  };

  // Stop drawing
  const stopDrawing = (e) => {
    e.preventDefault();
    setIsDrawing(false);
  };

  // Clear the canvas
  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  const logCall = async (log_message, status = 'log') => {
    const response = await axe.post(
      '/request-token-documents/customer/signature/log',
      {
        log_message,
        request_token,
        status,
      },
    );
    return response;
  };

  // Check if the canvas is empty
  const isCanvasEmpty = (canvas) => {
    const ctx = canvas.getContext('2d');
    const pixelData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
    return !pixelData.some((channel) => channel !== 0);
  };

  // Save and send the signature as a PNG to the API
  const saveSignature = async () => {
    const canvas = canvasRef.current;

    if (isCanvasEmpty(canvas)) {
      setErrorMessage('Per favore, fornisci una firma.');
      return;
    }

    const signatureDataUrl = canvas.toDataURL('image/png');

    // validate email
    if (!customerEmail || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(customerEmail)) {
      setErrorMessage('Per favore, inserisci un indirizzo email valido.');
      return;
    }

    try {
      setSubmitting(true);
      setErrorMessage('');

      // Send the PNG data to the API
      const response = await axe.post(
        '/request-token-documents/customer/signature',
        {
          signature: signatureDataUrl,
          request_token,
          customer_email: customerEmail,
        },
      );

      if (response.status === 200) {
        alert('Firma inviata con successo!');
        logCall('Firma inviata con successo dal cliente / agente', 'success');
        setSignatureSubmitted(true);
      } else {
        setErrorMessage('Invio della firma fallito. Riprova.');
      }
      setSubmitting(false);
    } catch (error) {
      console.error("Errore nell'invio della firma:", error);
      setErrorMessage('Invio della firma fallito. Riprova.');
      setSubmitting(false);
    }
  };

  const getDocuments = async () => {
    try {
      const response = await axe.get(
        `/request-token-documents/${request_token}`,
      );

      if (response.status === 200) {
        setDocuments(response.data);
        setLoading(false);
      } else {
        console.log(response);
        alert(
          `Recupero dei documenti fallito :(${
            response.data.error ? response.data.error : 'Server error'
          })`,
        );
        window.location.href = '/';
      }
    } catch (error) {
      console.error('Errore nel recupero dei documenti:', error);
    } finally {
      setLoading(false);
    }
  };

  const downloadPogDocument = async (doc_id) => {
    const response = await axe.get(
      `/request-token-documents/${request_token}/download/${doc_id}`,
    );
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const logSignatureStart = async () => {
    const response = logCall('Signature screen opened by customer / agent');
    console.log(response);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!signatureSubmitted) {
        logCall('User did not sign within 5 minutes');
        alert(
          'Non hai firmato entro 5 minuti. Per favore, riapri il link e firma il documento.',
        );
        window.location.href = '/';
      }
    }, 300000); // 5 minutes in milliseconds

    return () => clearTimeout(timer);
  }, [signatureSubmitted]);

  useEffect(() => {
    if (request_token) {
      getDocuments();
      logSignatureStart();
    }

    return () => {
      setDocuments(null);
    };
  }, [request_token]);

  // Disable scrolling when the component mounts
  useEffect(() => {
    // Disable scrolling
    const disableScroll = (e) => {
      e.preventDefault();
    };

    // Disable pull-to-refresh on touch devices
    const disablePullToRefresh = (e) => {
      if (e.touches && e.touches.length > 1) {
        e.preventDefault();
      }
    };

    document.body.style.overflow = 'hidden'; // Disable body scroll
    window.addEventListener('scroll', disableScroll, { passive: false });
    window.addEventListener('touchmove', disableScroll, { passive: false });
    window.addEventListener('touchstart', disablePullToRefresh, {
      passive: false,
    });

    return () => {
      // Re-enable scrolling
      document.body.style.overflow = 'auto';
      window.removeEventListener('scroll', disableScroll);
      window.removeEventListener('touchmove', disableScroll);
      window.removeEventListener('touchstart', disablePullToRefresh);
    };
  }, []);

  if (loading) {
    return <PageLoading />;
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Greenia SRL | Firma</title>
        <meta
          name="description"
          content="Scopri tutto quello che devi sapere sulle assicurazioni nel nostro blog!"
        />
      </Helmet>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 9999,
          margin: 'auto',
        }}
      >
        {signatureSubmitted ? (
          <div style={{ marginBottom: '20px' }}>
            <h3>Firma inviata con successo!</h3>
            <p>Grazie per aver firmato i documenti.</p>
          </div>
        ) : (
          <div className="container d-flex flex-column justify-content-center align-items-center">
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">
                Indirizzo email del cliente
              </label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Inserisci email"
                onChange={(e) => setCustomerEmail(e.target.value)}
              />
              <small id="emailHelp" className="form-text text-muted">
                Indirizzo email reale del cliente per inviare i documenti
                firmati.
              </small>
            </div>
            <canvas
              ref={canvasRef}
              width={350}
              height={350}
              style={{
                border: '1px solid #ccc',
                backgroundColor: '#f5f5f5',
              }}
              onMouseDown={startDrawing}
              onMouseMove={draw}
              onMouseUp={stopDrawing}
              onMouseLeave={stopDrawing}
              onTouchStart={startDrawing}
              onTouchMove={draw}
              onTouchEnd={stopDrawing}
            />
            <div style={{ margin: '5px 0' }}>
              <button className="btn btn-warning m-1" onClick={clearCanvas}>
                Cancella
              </button>
              <button
                className="btn btn-success m-1"
                onClick={() => {
                  if (window.confirm('Sei sicuro di voler inviare la firma?')) {
                    saveSignature();
                  }
                }}
              >
                {submitting ? 'Invio in corso...' : 'Invia firma'}
              </button>
            </div>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          </div>
        )}

        {documents && documents.length > 0 && (
          <>
            <h6>
              {signatureSubmitted
                ? 'Scarica documento firmato'
                : 'Documenti che necessitano di firma'}
            </h6>
            <ul>
              {documents.map((doc) => (
                <li key={doc.id}>
                  <a href="#" onClick={() => downloadPogDocument(doc.id)}>
                    {doc.original_filename}
                  </a>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </>
  );
}

export default SignatureScreen;
