import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { HomeWrapper } from '../Home.styles';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import { LoginCard, LoginStyles } from './Login.styles';
import { loginActiveCustomer } from '../../features/customerLogin/customerLoginActions';
import PageLoading from '../../common/components/elements/PageLoading';

const schema = yup.object({
  username: yup.string().required('Il nome utente e obbligatorio!'),
  saved_code_id: yup.string().required(' Il codice salvato e obbligatorio!'),
});

function Login() {
  const [show, setShow] = useState(false);
  const { loading } = useSelector((store) => store.customerLogin.login);

  const navigate = useNavigate();

  const closeMenu = () => {
    if (show === true) {
      setShow(false);
    }
  };

  const {
    setValue,
    trigger,
    formState: { errors, touchedFields },
    watch,
    handleSubmit,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      username: '',
      saved_code_id: '',
    },
  });

  const formData = watch();

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  const changeFormValues = (dataToChange) => {
    const keys = Object.keys(dataToChange);
    keys.forEach((key) => {
      setValue(key, dataToChange[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    });
    trigger(keys);
  };

  const submitData = () => {
    loginActiveCustomer(formData, navigate);
  };

  if (loading) {
    return <PageLoading />;
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Greenia SRL | Accedi</title>
        <meta name="description" content="Accedi su area cliente!" />
      </Helmet>
      <HomeWrapper onClick={() => closeMenu()}>
        <Navbar show={show} setShow={setShow} />
        <LoginStyles className="d-flex justify-content-center align-items-center ">
          <LoginCard className="card shadow-sm">
            <div className="card-body">
              <h3 className="text-center mb-4">Login</h3>
              <form onSubmit={handleSubmit(submitData)}>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Partiva IVA
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      isValid('username') ? 'border-success' : ''
                    } ${errors.username?.message ? 'border-danger' : ''}`}
                    placeholder="Inserici la partita IVA"
                    value={formData.username}
                    onChange={(e) => changeFormValues({ username: e.target.value })}
                  />
                  {errors.username?.message && (
                    <p className="error-text">{errors.username?.message}</p>
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Numero Poliza
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      isValid('saved_code_id') ? 'border-success' : ''
                    } ${errors.saved_code_id?.message ? 'border-danger' : ''}`}
                    placeholder="Inserisci il numero della poliza..."
                    value={formData.saved_code_id}
                    onChange={(e) => changeFormValues({ saved_code_id: e.target.value })}
                  />
                  {errors.saved_code_id?.message && (
                    <p className="error-text">
                      {errors.saved_code_id?.message}
                    </p>
                  )}
                </div>
                <button type="submit" className="btn btn-success w-100">
                  Login
                </button>
              </form>
            </div>
          </LoginCard>
        </LoginStyles>
        <Footer />
      </HomeWrapper>
    </>
  );
}

export default Login;
