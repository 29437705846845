import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router';
import { motion } from 'framer-motion';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import moment from 'moment/moment';
import { HomeWrapper } from './Home.styles';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import {
  Container, FrequentlyQuestions, WorkWithUsHeader, WorkWithUsParagraph,
} from './WorkWithUs.styles';
import workWithUsBg from '../assets/images/work_with_us_bg.svg';
import Partners from '../components/Partners';
import { sendContactData } from '../utils/sendContactData';
import { ProductsInfo } from '../components/ProductsInfo';
import { Accordion } from '../components/FAQSection.styles';
import getMaxAllowedBirthday from '../utils/getMaxAllowedBirthday';
import { genders } from '../common/constants';

const schema = yup.object({
  name: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) => (gender !== 'G'
      ? schema
        .required('Il campo è obbligatorio. Inserisci il nome!')
      : schema)),
  surname: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) => (gender !== 'G'
      ? schema
        .required('Il campo è obbligatorio. Inserisci il cognome!')
      : schema)),
  gender: yup.string().required('Il campo è obbligatorio. Seleziona genere!'),
  // date_of_birth: yup
  //   .string()
  //   .required('Il campo è obbligatorio. Seleziona data di nascita!')
  //   .test('valid-date', 'La data di nascita non è valida', (value) => {
  //     const date = moment(value, 'YYYY-MM-DD', true);
  //     const year = date.year();
  //     const currentYear = new Date().getFullYear();

  //     // Ensure the date is valid and within the allowed range.
  //     return date.isValid() && year <= currentYear - 18 && year >= 1935;
  //   }),
  date_of_birth: yup
    .string()
    .nullable()
    .when('gender', ([gender], schema) => (gender !== 'G'
      ? schema.required('Il campo è obbligatorio. Seleziona data di nascita!')
        .test('valid-date', 'La data di nascita non è valida', (value) => {
          if (!value) return true;
          const date = moment(value, 'YYYY-MM-DD', true);
          const year = date.year();
          const currentYear = new Date().getFullYear();

          if (year > currentYear - 18 || year < 1935) {
            return false;
          }
          return date.isValid();
        })
      : schema)),
  business_name: yup
    .string()
    .nullable()
    .when('gender', {
      is: 'G',
      then: () => yup
        .string()
        .required("Il campo è obbligatorio. Inserisci il nome dell'azienda!"),
    }),
  email: yup.string().required('La email e un campo obligatorio!'),
  phone: yup
    .string()
    .required('Il campo è obbligatorio. Inserisci numero di telefono!')
    .matches(
      /^[3]{1}[0-9]{8,9}$/,
      'Il numero non e corretto',
    ),
  accept_terms: yup.boolean().oneOf([true], "Devi accettare di aver letto l'informativa"),
});

function WorkWithUs() {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(1);
  const navigate = useNavigate();

  const {
    register, formState: { errors, isSubmitSuccessful }, handleSubmit, reset, watch, setValue,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      surname: '',
      date_of_birth: '',
      email: '',
      phone: '',
      accept_terms: 0,
      token: '',
      gender: '',
    },
  });

  const answers = watch();

  const cardVariants = {
    offscreen: { opacity: 0, y: -20 },
    onscreen: {
      opacity: 1,
      y: 0,

      transition: {
        duration: 0.7,
      },
    },
  };

  const trackConversion = () => {
    if (window.lintrk) {
      window.lintrk('track', { conversion_id: 19842521 });
      // alert('Here');
    } else {
      console.error('LinkedIn tracking is not loaded');
    }
  };

  const sendData = (data) => {
    sendContactData(data, setLoading, navigate);
    trackConversion();
  };

  const closeMenu = () => {
    if (show === true) {
      setShow(false);
    }
  };

  const toggleAccordion = (select) => {
    if (selected === select) {
      setSelected(0);
    } else {
      setSelected(select);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({
        email: '',
        phone: '',
        accept_terms: 0,
      });
    }
  }, [isSubmitSuccessful, reset]);

  useEffect(() => {
    const triggerValidation = {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    };

    if (answers.gender === 'G') {
      setValue('date_of_birth', null);
      setValue('business_name', null);
      setValue('vat_number', null);
    } else if (['M', 'F'].includes(answers.gender) && answers.date_of_birth === null) {
      setValue('date_of_birth', '', triggerValidation);
      setValue('born_abroad', false, triggerValidation);
      setValue('name', '', triggerValidation);
      setValue('surname', '', triggerValidation);
    }
  }, [answers.gender, answers.date_of_birth]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Greenia SRL | Collabora Con Noi</title>
        <meta name="description" content="Scopri tutto quello che devi sapere sulle assicurazioni nel nostro blog!" />
      </Helmet>
      <HomeWrapper onClick={() => closeMenu()}>
        <Navbar show={show} setShow={setShow} />
        <Container>
          <div className="container py-4">
            <div className="row g-4 ">
              <div className="col-12 col-lg-6 d-flex align-items-center justify-content-center flex-column">
                <div className=" me-auto mb-3">
                  <WorkWithUsHeader>Collabora con Noi</WorkWithUsHeader>
                  <WorkWithUsParagraph>
                    Compila il form e un nostro rappresentante
                    ti contatterà appena possibile!
                  </WorkWithUsParagraph>
                </div>
                <div className="w-100">
                  <form onSubmit={handleSubmit(sendData)}>
                    <div className="mb-3 ">
                      <div className="d-flex justify-content-start align-items-start gap-3">
                        {genders.map((gender, index) => (
                          <label className="d-flex" style={{ fontWeight: 'bold' }} key={index}>
                            <p className="mb-0 me-2">{gender.label}</p>
                            <input
                              className="radio"
                              name="gender"
                              type="radio"
                              value={gender.value}
                              style={{ transform: 'scale(1.5)', boxShadow: 'none' }}
                              {...register('gender')}
                            />
                          </label>

                        ))}
                      </div>
                      <span>{errors.gender?.message}</span>
                    </div>
                    {answers.gender === 'G' ? (
                      <>
                        <div className="mb-3">
                          <label>Nome Sociale</label>
                          <input type="text" {...register('business_name')} className={`${errors.business_name ? 'invalid form-control' : 'form-control'}`} name="business_name" placeholder="Inserisci Nome Sociale..." />
                          <span>{errors.business_name?.message}</span>
                        </div>
                        <div className="mb-3">
                          <label>Partita IVA</label>
                          <input type="text" {...register('vat_number')} className={`${errors.vat_number ? 'invalid form-control' : 'form-control'}`} name="vat_number" placeholder="Inserisci Partita IVA..." />
                          <span>{errors.vat_number?.message}</span>
                        </div>
                      </>
                    )
                      : (
                        <>
                          <div className="mb-3">
                            <label>Nome</label>
                            <input type="text" {...register('name')} className={`${errors.name ? 'invalid form-control' : 'form-control'}`} name="name" placeholder="Inserisci Nome..." />
                            <span>{errors.name?.message}</span>
                          </div>
                          <div className="mb-3">
                            <label>Cognome</label>

                            <input type="text" {...register('surname')} className={`${errors.surname ? 'invalid form-control' : 'form-control'}`} name="surname" placeholder="Inserisci Cognome..." />
                            <span>{errors.surname?.message}</span>
                          </div>
                          <div className="mb-3">
                            <label>Data di Nascita</label>

                            <input
                              type="date"
                              {...register('date_of_birth')}
                              className={`${errors.date_of_birth ? 'invalid form-control' : 'form-control'}`}
                              name="date_of_birth"
                              placeholder="Seleziona data..."
                              min="1935-01-01"
                              max={getMaxAllowedBirthday()}
                            />
                            <span>{errors.date_of_birth?.message}</span>
                          </div>
                        </>
                      )}

                    <div className="mb-3">
                      <label>Email</label>

                      <input type="email" {...register('email')} className={`${errors.email ? 'invalid form-control' : 'form-control'}`} name="email" placeholder="Indirizzo email..." />
                      <span>{errors.email?.message}</span>
                    </div>
                    <div className="mb-3">
                      <label>Telefono</label>

                      <input type="text" {...register('phone')} className={`${errors.phone ? 'invalid form-control form-control-md' : 'form-control form-control-md'}`} name="phone" placeholder="Numero telefono..." />
                      <span>{errors.phone?.message}</span>
                    </div>
                    <div className="form-check">
                      <input type="checkbox" {...register('accept_terms')} className={`${errors.accept_terms ? 'invalid form-check-input' : 'form-check-input'}`} name="accept_terms" />
                      <label htmlFor="" className="form-check-label form-check-label-lg text-white accept-terms">Accetto e confermo di aver preso visione dell'informativa ai sensi dell'art. 13 del regolamento UE 679/2016, acconsentendo al trattamento dei miei dati secondo le finalità ivi specificate</label>
                    </div>
                    <span>{errors.accept_terms?.message}</span>
                    <div className="my-3">
                      <button className="btn btn-light btn-md px-4" disabled={!!loading}>{loading ? 'Inviando dati...' : 'Invia'}</button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="d-none col-lg-6 d-lg-block">
                <img src={workWithUsBg} alt="Collabora Con Noi" className="img-fluid ms-auto d-table" />
              </div>
            </div>
          </div>
        </Container>
        <Partners />
        <ProductsInfo />
        <FrequentlyQuestions className="container-sm mb-5">
          <motion.h3
            id="faq"
            variants={cardVariants}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
            className="mb-4"
          >
            Domande Frequenti
          </motion.h3>
          <Accordion>
            <motion.div className="h5-accordion" initial={false} onClick={() => toggleAccordion(1)}>
              <span>Cos'è Greenia?</span>
              {selected === 1 ? (
                <FaCaretDown color="white" />
              ) : (
                <FaCaretUp color="white" />
              )}
            </motion.div>
            {selected === 1 ? (
              <motion.p
                className="open"
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.6,
                  },
                }}
                exit={{ opacity: 0 }}
              >
                Immagina Greenia come la tua "tecnologia-assistente" nel mondo
                assicurativo, pronto a semplificare e modernizzare la tua
                operatività. In un colpo solo, avrai un portale all'avanguardia
                per gestire il tuo portafoglio, oltre a sbirciare tra le
                migliori tariffe di mercato con un sorriso. Unisciti alla
                squadra degli "smart player" italiani. Scommettiamo che sarà
                un'esperienza da campioni?
              </motion.p>
            ) : (
              ''
            )}
            <div className="h5-accordion" onClick={() => toggleAccordion(2)}>
              <span>Come posso emettere contratti con Greenia?</span>
              {selected === 2 ? (
                <FaCaretDown color="white" />
              ) : (
                <FaCaretUp color="white" />
              )}
            </div>
            {selected === 2 ? (
              <motion.p
                className="open"
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.8,
                  },
                }}
                exit={{ opacity: 0 }}
              >
                Semplicità allo stato puro! Grazie alla nostra piattaforma
                digitale all'avanguardia e al consulente dedicato a te, sarà un
                gioco da ragazzi definire con il cliente il prodotto perfetto
                per le sue esigenze. Dimentica carta e attese interminabili: ora
                puoi emettere i prodotti delle principali Compagnie assicurative
                in tempo reale. Con Greenia, la velocità è il nostro motto!
              </motion.p>
            ) : (
              ''
            )}
            <div className="h5-accordion" onClick={() => toggleAccordion(3)}>
              <span>Ho un supporto dedicato?</span>
              {selected === 3 ? (
                <FaCaretDown color="white" />
              ) : (
                <FaCaretUp color="white" />
              )}
            </div>
            {selected === 3 ? (
              <motion.p
                className="open"
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.8,
                  },
                }}
                exit={{ opacity: 0 }}
              >
                Assolutamente! Sin dai primi passi, avrai il tuo Account
                personale sempre al tuo fianco: sarai la nostra priorità.
                Insieme, creerete strategie vincenti per la gestione del
                portafoglio e affronterete anche le sfide più intricate. E non
                temere se il tuo consulente dovesse essere momentaneamente
                occupato, perché un team di esperti è pronto a entrare in campo
                per darti una mano. Con Greenia la tua squadra è sempre pronta a
                sostenerti!
              </motion.p>
            ) : (
              ''
            )}
            <div className="h5-accordion" onClick={() => toggleAccordion(4)}>
              <span>I documenti di collaborazione?</span>
              {selected === 4 ? (
                <FaCaretDown color="white" />
              ) : (
                <FaCaretUp color="white" />
              )}
            </div>
            {selected === 4 ? (
              <motion.p
                className="open"
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.8,
                  },
                }}
                exit={{ opacity: 0 }}
              >
                Facciamo squadra per tenere ogni cosa sotto controllo e a
                portata di clic! Hai l'accesso ai documenti tuoi e dei tuoi
                clienti, pronti per il download in qualsiasi momento. E addio
                alla carta: la firma digitale fa il suo ingresso, eliminando la
                necessità di stampare qualsiasi cosa. E se dovessi avere bisogno
                di una mano, il tuo Account personale è pronto all'azione. Con
                lui al timone, non c'è sfida troppo grande da affrontare.
                Greenia è qui per semplificare la tua vita assicurativa!
              </motion.p>
            ) : (
              ''
            )}
            <div className="h5-accordion" onClick={() => toggleAccordion(5)}>
              <span>
                Quali metodi di pagamento ha a disposizione il cliente?
              </span>
              {selected === 5 ? (
                <FaCaretDown color="white" />
              ) : (
                <FaCaretUp color="white" />
              )}
            </div>
            {selected === 5 ? (
              <motion.p
                className="open"
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.8,
                  },
                }}
                exit={{ opacity: 0 }}
              >
                Tutti quelli disponibili ovvero Carta di credito, Carta di
                debito, Bancomat, Bonifico e contanti.
              </motion.p>
            ) : (
              ''
            )}
            <div className="h5-accordion" onClick={() => toggleAccordion(6)}>
              <span>Quando pagate le provvigioni?</span>
              {selected === 6 ? (
                <FaCaretDown color="white" />
              ) : (
                <FaCaretUp color="white" />
              )}
            </div>
            {selected === 6 ? (
              <motion.p
                className="open"
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: {
                    duration: 0.8,
                  },
                }}
                exit={{ opacity: 0 }}
              >
                Entro il 10 del mese successivo.
              </motion.p>
            ) : (
              ''
            )}
          </Accordion>
        </FrequentlyQuestions>
        <Footer />
      </HomeWrapper>
    </>
  );
}

export default WorkWithUs;
