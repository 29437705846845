import { useState, useEffect } from 'react';
import Italian from '@uppy/locales/lib/it_IT';
import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/react';
import uploadIcon from '../../../assets/images/upload-icon.svg';
import { UppyContainer } from './Uploader.styles';

export function Uploader(props) {
  const { note, allowedFileTypes, onFileUpload } = props;

  const [uppy, setUppy] = useState();

  useEffect(() => {
    const uploadConfigurationFileUpload = {
      locale: Italian,
      restrictions: {
        maxFileSize: 3000000,
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes:
          allowedFileTypes !== undefined
            ? allowedFileTypes
            : ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'],
      },
    };

    const uppy = new Uppy(uploadConfigurationFileUpload);

    uppy.on('file-added', (file) => {
      onFileUpload(file.data);
    });
    setUppy(uppy);
  }, []);

  if (uppy === undefined) {
    return <></>;
  }

  return (
    <UppyContainer icon={uploadIcon}>
      <Dashboard uppy={uppy} note={note} proudlyDisplayPoweredByUppy={false} />
    </UppyContainer>
  );
}
