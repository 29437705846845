import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  login: {
    error: null,
    loading: false,
    is_auth: false,
    active_customer_id: null,
    active_customer: null,
  },
  customer_request: {
    error: null,
    loading: true,
    request: null,
  },
};

export const customerLoginSlice = createSlice({
  name: 'customerLogin',
  initialState,
  reducers: {
    loginCustomerPending: (state) => {
      state.login.loading = true;
      state.login.error = null;
    },
    loginCustomerRejected: (state, { payload }) => {
      state.login.loading = false;
      state.login.error = payload;
    },
    loginCustomerSuccess: (state, { payload }) => {
      state.login.loading = false;
      state.login.is_auth = payload.data.is_auth;
      state.login.active_customer_id = payload.data.active_customer_id;
      state.login.active_customer = payload.data.active_customer;
    },
    getRequestClientPending: (state) => {
      state.customer_request.loading = true;
      state.customer_request.error = null;
    },
    getRequestClientRejected: (state, { payload }) => {
      state.customer_request.loading = false;
      state.customer_request.error = payload;
    },
    getRequestClientSuccess: (state, { payload }) => {
      state.customer_request.loading = false;
      state.customer_request.request = payload.data;
    },

  },
});
