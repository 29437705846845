import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import Navbar from '../components/Navbar';
import { NoTeamFound, TeamStyles, TeamTitle } from './Team.styles';
import PageLoading from '../common/components/elements/PageLoading';
import Footer from '../components/Footer';
import Pagination from '../components/Pagination';
import TeamCard from '../components/TeamCard';
import { getTeam } from '../features/team/teamActions';
import { HomeWrapper } from './Home.styles';

function Team() {
  const [show, setShow] = useState(false);
  const {
    loading, teams, page, totalPages,
  } = useSelector(
    (store) => store.teams.teams,
  );

  const closeMenu = () => {
    if (show === true) {
      setShow(false);
    }
  };

  useEffect(() => {
    getTeam();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  if (loading) {
    return <PageLoading />;
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Greenia SRL | Team</title>
        <meta name="description" content="Scopri il nostro team!" />

        <meta name="apple-mobile-web-app-title" content="Greenia SRL | Team" />
        <meta
          name="bingbot"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta
          name="twitter:card"
          content="https://greenia.it/images/logo.png"
        />
        <meta name="twitter:description" content="Scopri il nostro team!" />
        <meta name="twitter:title" content="Greenia SRL | Team" />
        <meta
          name="twitter:image"
          content="https://greenia.it/images/logo.png"
        />
        <meta property="og:locale" content="it_IT" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Greenia SRL | Team" />
        <meta property="og:description" content="Scopri il nostro team!" />
        <meta
          property="og:image"
          content="https://greenia.it/images/logo.png"
        />
        <meta property="og:url" content="https://greenia.it/" />
        <meta
          property="og:image:secure_url"
          content="https://greenia.it/images/logo.png"
        />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:alt" content="Greenia Logo" />
      </Helmet>
      <HomeWrapper onClick={() => closeMenu()}>
        <Navbar show={show} setShow={setShow} />
        <TeamStyles className="container">
          <TeamTitle>
            <h1>Il Nostro Team</h1>
          </TeamTitle>

          <div className="row my-5">
            {teams.data.length === 0 && (
              <NoTeamFound className="rounded-2 p-3">
                <p className="m-0"> Nessun Membro Team Trovato.</p>
              </NoTeamFound>
            )}
            {teams.data.map((team, index) => (
              <TeamCard key={index} team={team} />
            ))}
          </div>
        </TeamStyles>
        {teams.length !== 0 && totalPages !== 1 ? (
          <Pagination
            page={page}
            last_page={totalPages}
            collectData={getTeam}
          />
        ) : (
          ''
        )}

        <Footer />
      </HomeWrapper>
    </>
  );
}

export default Team;
