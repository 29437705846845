/* eslint-disable */
import { useEffect } from 'react'
import { Loader } from '@googlemaps/js-api-loader'
import { googleLoader } from './utils/google'

function MapContainer({
  hidden = false,
  disabled = false,
  setValue,
  registration,
  lat = 41.902782,
  lng = 12.496366,
  error = null,
  latKeyName = 'lat',
  lngKeyName = 'lng',
  addressKeyName = 'address',
  label = 'Indirizzo'
}) {
  useEffect(() => {
    setTimeout(() => {
      googleLoader.load().then((google) => {
        const myLatLng = {
          lat,
          lng
        }

        const map = new google.maps.Map(document.getElementById('map'), {
          center: myLatLng,
          zoom: 15
        })

        new google.maps.Marker({
          position: myLatLng,
          map,
          title: 'Hello Greenia!'
        })

        const autocomplete = new google.maps.places.Autocomplete(
          document.getElementById('autocomplete'),
          {
            fields: ['formatted_address', 'geometry', 'name'],
            types: ['address'],
            componentRestrictions: { country: 'it' }
          }
        )

        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace()

          if (!place.geometry) {
            setValue('address', '')
          } else {
            const map = new google.maps.Map(document.getElementById('map'), {
              zoom: 15,
              center: place.geometry.location
            })

            new google.maps.Marker({
              position: place.geometry.location,
              map
            })

            setValue(addressKeyName, place.formatted_address)
            setValue(latKeyName, place.geometry.location.lat())
            setValue(lngKeyName, place.geometry.location.lng())
          }
        })
      })

      return () => {
        if (googleLoader) {
          googleLoader.reset()
          delete window.google
          Loader.instance = undefined
        }
      }
    }, 1000)
  }, [lat, lng])
  return (
    <div className='col-span-full mt-3' hidden={hidden}>
      <label className=' font-medium text-primary-default text-sm'>
        {label} *
      </label>
      <input
        id='autocomplete'
        disabled={disabled}
        placeholder='Inserisci Indirizzo...'
        className={`form-control ${error ? 'is-invalid' : ''} ${
          disabled ? 'bg-slate-200' : 'bg-transparent'
        }`}
        {...registration}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()
          }
        }}
      />
      {error && <div className='invalid-feedback d-block'>{error}</div>}
      <div id='map' className='w-100 my-2' style={{ height: 300 }} />
    </div>
  )
}

export default MapContainer
