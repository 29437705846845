import React, { useState } from 'react';
import { FaBan, FaEdit, FaTimes } from 'react-icons/fa';
import { FaCarBurst, FaRotateRight } from 'react-icons/fa6';
import {
  ModalBody,
  ModalBox,
  ModalClientPostVendita,
  ModalHeader,
} from '../pages/ClientPage.styles';
import ReportAccident from './PostVendita/ReportAccident';
import ReplacementForm from './PostVendita/ReplacementForm';
import CancellationForm from './PostVendita/CancellationForm';
import ModificationForm from './PostVendita/ModificationForm';

function ModalClientPage({
  setPostVenditaQuote,
  postVenditaQuote,
  setSelectedQuote,
  selectedQuote,
  fetchData,
  active_customer,
}) {
  const [accidentFormOpen, setAccidentFormOpen] = useState(false);
  const [replacementFormOpen, setReplacementFormOpen] = useState(false);
  const [cancellationFormOpen, setCancellationFormOpen] = useState(false);
  const [modificationFormOpen, setModificationFormOpen] = useState(false);

  const openAccidentForm = () => {
    setAccidentFormOpen(true);
  };

  const openReplacementForm = () => {
    setReplacementFormOpen(true);
  };

  const openCancellationForm = () => {
    setCancellationFormOpen(true);
  };

  const openModificationForm = () => {
    setModificationFormOpen(true);
  };

  const closeAll = () => {
    setAccidentFormOpen(false);
    setCancellationFormOpen(false);
    setModificationFormOpen(false);
    setReplacementFormOpen(false);
    setPostVenditaQuote(null);
    setSelectedQuote(null);
  };

  return (
    <>
      <ModalClientPostVendita>
        <ModalBox className="shadow rounded-3 position-relative">
          <ModalHeader className="d-flex align-items-center justify-content-between p-3 bg-secondary">
            <h4 className="text-white mb-0">Post Vendita</h4>
            <button className="btn btn-light btn-sm" onClick={() => closeAll()}>
              <FaTimes />
            </button>
          </ModalHeader>
          {accidentFormOpen && (
            <ReportAccident
              postVenditaQuote={postVenditaQuote}
              closeAll={closeAll}
              fetchData={fetchData}
              active_customer={active_customer}
            />
          )}

          {replacementFormOpen && (
            <ReplacementForm
              postVenditaQuote={postVenditaQuote}
              selectedQuote={selectedQuote}
              closeAll={closeAll}
              fetchData={fetchData}
              active_customer={active_customer}
            />
          )}

          {cancellationFormOpen && (
            <CancellationForm
              postVenditaQuote={postVenditaQuote}
              selectedQuote={selectedQuote}
              closeAll={closeAll}
              fetchData={fetchData}
              active_customer={active_customer}
            />
          )}

          {modificationFormOpen && (
            <ModificationForm
              postVenditaQuote={postVenditaQuote}
              selectedQuote={selectedQuote}
              closeAll={closeAll}
              fetchData={fetchData}
              active_customer={active_customer}
            />
          )}

          {!accidentFormOpen
            && !replacementFormOpen
            && !cancellationFormOpen
            && !modificationFormOpen && (
              <ModalBody className="p-4">
                <div className="row g-2">
                  <div className="col-6">
                    <button
                      className="btn btn-outline-success w-100"
                      onClick={() => openAccidentForm()}
                    >
                      <FaCarBurst size={60} />
                      <h5>Denuncia Sinistro</h5>
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      className="btn btn-outline-success w-100"
                      onClick={() => openReplacementForm()}
                    >
                      <FaRotateRight size={60} />
                      <h5>Sostituzione</h5>
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      className="btn btn-outline-success w-100"
                      onClick={() => openCancellationForm()}
                    >
                      <FaBan size={60} />
                      <h5>Cessazione</h5>
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      className="btn btn-outline-success w-100"
                      onClick={() => openModificationForm()}
                    >
                      <FaEdit size={60} />
                      <h5>Modifica dati non tariffari</h5>
                    </button>
                  </div>
                </div>
              </ModalBody>
          )}
        </ModalBox>
      </ModalClientPostVendita>
    </>
  );
}

export default ModalClientPage;
