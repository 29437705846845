export const quotationTypes = [
  {
    id: 'res_civile',
    name: 'Responsabilita Civile',
  },
  {
    id: 'tutela_legale',
    name: 'Tutela Legale',
  },
  {
    id: 'assistenza_stradale',
    name: 'Assistenza Stradale',
  },
  {
    id: 'infortuni_conducente',
    name: 'Infortuni Conducente',
  },
  {
    id: 'cristalli',
    name: 'Cristalli',
  },
  {
    id: 'rivalsa',
    name: 'Rivalsa',
  },
  {
    id: 'rct_cose_quote',
    name: 'RCT - Danni Alle Cose Su Cui Si Eseguono Lavori',
  },
  {
    id: 'rct_veicoli_quote',
    name: 'RCT - Danni Ai Veicoli In Consegna e Custodia',
  },
  {
    id: 'rct_postuma_autofficine_quote',
    name: 'RCT - RC Postuma Autofficine',
  },
  {
    id: 'rct_postuma_12_quote',
    name: 'RCT - RC Postuma 12 Mesi',
  },
  {
    id: 'rct_postuma_dm_quote',
    name: 'RCT - RC Postuma D.M.37/2008',
  },
  {
    id: 'rct_furto_quote',
    name: 'RCT - Danni Da Furto',
  },
  {
    id: 'rco_quote',
    name: 'RCO',
  },
  {
    id: 'rc_prodotto_quote',
    name: 'RC Prodotto',
  },
  {
    id: 'do_quote',
    name: 'D&O',
  },
  {
    id: 'rct_rc_impresa_edile',
    name: 'RCT - RC IMPRESA EDILE',
  },
  {
    id: 'rc_personale_soggeti_dipendenti_servizio_struttura',
    name: 'R.C. PERSONALE DEI SOGGETTI NON DIPENDENTI AL SERVIZIO DELLA STRUTTURA',
  },
  {
    id: 'danni_veicoli_prestatori_fornitori_parcheggiati_impresa',
    name: 'DANNI A VEICOLI DEI PRESTATORI DI LAVORO E FORNITORI PARCHEGGIATI NEGLI SPAZI DI PERTINENZA DELL’IMPRESA ASSICURATA',
  },
  {
    id: 'commitenza_di_lavori_straordinaria_manutenzione',
    name: 'COMMITTENZA DI LAVORI DI STRAORDINARIA MANUTENZIONE',
  },
  {
    id: 'attivita_intrattenimento_arte_varia',
    name: 'ATTIVITÀ DI INTRATTENIMENTO E ARTE VARIA',
  },
  {
    id: 'danni_causato_animali_agriturismo',
    name: 'DANNI CAUSATI DAGLI ANIMALI - ATTIVITÀ DI AGRITURISMO',
  },
  {
    id: 'organizzazione_gite_ed_escursioni',
    name: 'ORGANIZZAZIONE DI GITE ED ESCURSIONI',
  },
  {
    id: 'estensione_tipo_ristorante_sportiva_ecc',
    name: 'ESTENSIONE TIPOLOGIA ATTIVITÀ (RISTORAZIONE, SPORTIVA, STABILIMENTI TERMALI, SPA ECC.)',
  },
  {
    id: 'estensione_passeggiate_cavallo',
    name: 'ESTENSIONE PASSEGGIATE A CAVALLO - DANNI AI CAVALIERI',
  },
  {
    id: 'estensione_veicoli_dei_clienti',
    name: 'ESTENSIONE VEICOLI DEI CLIENTI',
  },
  // { id: 'kasko', name: 'Kasko completa' },
  { id: 'collisione', name: 'Collisione Animali Selvatici' },
  { id: 'eventi_socio_politici', name: 'Eventi Socio Politici' },
  { id: 'eventi_naturali', name: 'Eventi naturali' },
  // { id: 'bonus_protetto', name: 'Bonus protetto' },
  { id: 'furto_incendio', name: 'Furto e incendio' },
  {
    id: 'incendio_fabbricato',
    name: 'Incendio e scoppio fabbricato',
  },
  {
    id: 'incendio_contentuto',
    name: 'Incendio e scoppio contenuto',
  },
  {
    id: 'danni_elettrici_contenuto',
    name: 'Danni elettrici al contenuto',
  },
  {
    id: 'spese_legali_propietario_immobiliare',
    name: 'Spese legali proprietario immobiliare',
  },
  {
    id: 'danni_terzi_famiglia',
    name: 'Danni a terzi famiglia e conduzione',
  },
  {
    id: 'danni_terzi_propietario',
    name: 'Danni a terzi proprietario',
  },
  {
    id: 'spese_legali_famiglia',
    name: 'Spese legali famiglia',
  },
  {
    id: 'furto_casa',
    name: 'Furto',
  },
  {
    id: 'inagibilita_abitazione',
    name: 'Inagibilità abitazione',
  },
  {
    id: 'inagibilita',
    name: 'Inagibilità',
  },
  {
    id: 'danni_elettrici_al_fabricato',
    name: 'Danni elettrici al fabbricato',
  },
  {
    id: 'fuori_uscita_di_acqua_gas',
    name: 'Fuoriuscita di acqua e gas',
  },
  {
    id: 'assistenza_malattia',
    name: 'Assistenza Malattia',
  },
  {
    id: 'adattamento_abitazione_malattia',
    name: 'Adattamento Abitazione Malattia',
  },
  {
    id: 'rsm_grave_malattia',
    name: 'RSM Grave Malattia',
  },
  {
    id: 'indennita_sostitutiva_malattia',
    name: 'Indennita Sostitutiva Malattia',
  },
  {
    id: 'daria_da_convalescenza_malattia',
    name: 'Daria da Convalescenza Post Terapia Intensiva Malattia',
  },
  {
    id: 'indennitaria_da_malattia',
    name: 'Indennitaria da Malattia',
  },
  {
    id: 'rsm_post_ricovero_malattia',
    name: 'RSM Post Ricovero Malattia',
  },
  {
    id: 'assistenza_post_infortunio',
    name: 'Assistenza Post Infortunio',
  },
  {
    id: 'adattamento_abitazione_veicolo_infortunio',
    name: 'Adattamento Abitazione+Veicolo Infortunio',
  },
  {
    id: 'indennizzo_da_infortunio',
    name: 'Indennizzo da Infortunio',
  },
  {
    id: 'capitale_caso_morte_infortunio',
    name: 'Capitale Caso Morte Infortunio',
  },
  {
    id: 'indennita_da_infortunio_grave',
    name: 'Indennità da Infortunio Grave',
  },
  {
    id: 'rsm_post_infortunio',
    name: 'RSM Post Infortunio',
  },
];

export const ownerTypes = [
  {
    id: '1',
    name: 'Si, proprietà principale',
  },
  {
    id: '2',
    name: 'Si, proprietà secondaria',
  },
  {
    id: '3',
    name: 'Si, Locato a terzi',
  },
  {
    id: '4',
    name: 'No, sono in affitto',
  },
];

export const houseTypes = [
  {
    id: '1',
    name: 'Villa',
  },
  {
    id: '2',
    name: 'Appartamento',
  },
];

export const workType = [
  {
    id: 'attivita_manuali',
    name: 'Attività Manuali',
  },
  {
    id: 'attivita_non_manuali',
    name: 'Attività non Manuali',
  },
];

export const primaQuoteTypes = [
  { id: 'kasko', name: 'Kasko completa' },
  { id: 'collisione', name: 'Kasko collisione' },
  { id: 'eventi_sociopolitici', name: 'Atti vandalici' },
  { id: 'eventi_naturali', name: 'Eventi naturali' },
  { id: 'bonus_protetto', name: 'Bonus protetto' },
  { id: 'furto_incendio', name: 'Furto e incendio' },
  {
    id: 'tutela_legale',
    name: 'Tutela Legale',
  },
  {
    id: 'assistenza_stradale',
    name: 'Assistenza Stradale',
  },
  {
    id: 'infortuni_conducente',
    name: 'Infortuni Conducente',
  },
  {
    id: 'cristalli',
    name: 'Cristalli',
  },
  {
    id: 'protezione_rivalse',
    name: 'Protezione Rivalse',
  },
];

export const set_informativi = {
  1: 'set_informativo_hcc_ingegneri.pdf',
  2: 'set_informativo_hcc_architetto.pdf',
  3: 'set_informativo_hcc_avvocato.pdf',
  4: 'set_informativo_hcc_Commercialista.pdf',
  5: 'set_informativo_hcc_consulente_del_lavoro.pdf',
  6: 'set_informativo_hcc_agente_immobiliare.pdf',
  7: 'set_informativo_hcc_agenti_di_attivita_finanziaria.pdf',
  8: 'set_informativo_hcc_amministratore_condominio.pdf',
  9: 'set_informativo_hcc_geometri.pdf',
  10: 'set_informativo_hcc_mediatore_creditizi.pdf',
  11: 'set_informativo_hcc_periti.pdf',
  12: 'set_informativo_hcc_tributarista.pdf',
  13: '',
  14: 'set_informativo_hcc_ag_commercio.pdf',
  15: 'set_informativo_hcc_agronomi.pdf',
  16: 'set_informativo_hcc_societa_di_edp.pdf',
  17: 'set_informativo_hcc_chimichi.pdf',
  18: 'set_informativo_hcc_dpo.pdf',
  19: 'set_informativo_hcc_geologi.pdf',
  20: 'set_informativo_hcc_miscellaneous.pdf',
  21: 'set_informativo_hcc_miscellaneous_energetica.pdf',
  22: 'set_informativo_hcc_psicologo.pdf',
  23: 'set_informativo_hcc_revisore_legale_dei_conti.pdf',
  24: '',
  // "24": "set_informativo_hcc_singolo_componente_mediazione.pdf",
  25: 'set_informativo_hcc_tecnologo_alimentare.pdf',
  26: 'set_informativo_hcc_conformita.pdf',
};

export const reasonForReplacement = [
  { id: 'distruzione_o_demolizione_del_veicolo', name: 'Distruzione o demolizione del veicolo' },
  { id: 'cessazione_definitiva_della_circolazione_deicolo', name: 'Cessazione definitiva della circolazione deicolo' },
  { id: 'esportazione_definitiva_del_veicolo', name: 'Esportazione definitiva del veicolo' },
  { id: 'alienazione_o_deposito_in_conto_vendita', name: 'Alienazione o deposito in conto vendita' },
  { id: 'furto_totale_dei_veicolo', name: 'Furto totale dei veicolo' },
];
