import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import moment from 'moment';
import { FaDownload, FaFile } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { UploadNavbar } from './UploadBrokerDocuments.styles';
import { Logo } from '../components/Navbar.styles';
import logoGreen from '../assets/images/logo-white.svg';
import PageLoading from '../common/components/elements/PageLoading';
import { getCustomerInfo } from '../features/customerLogin/customerLoginActions';
import { TableRowStyle } from './ClientPage.styles';
import {
  houseTypes,
  ownerTypes,
  primaQuoteTypes,
  quotationTypes,
  set_informativi,
} from '../common/utils/constants';
import Footer from '../components/Footer';
import UploadedFile from '../common/UploadedFile';
import axe from '../services/axios-client/axiosClient';
import { downloadFile } from '../services/axios-client/axeCommons';
import ModalClientPage from '../components/ModalClientPage';
import ListPostVendita from '../components/PostVendita/ListPostVendita';

function ClientPage() {
  const { requestToken, clientId } = useParams();
  const [postVenditaQuote, setPostVenditaQuote] = useState(null);
  const [selectedQuote, setSelectedQuote] = useState(null);
  const { is_auth, active_customer } = useSelector(
    (store) => store.customerLogin.login,
  );

  const selectQuotePostVendita = (quote) => {
    setSelectedQuote(quote);
    setPostVenditaQuote(quote.id);
  };

  const { loading, request } = useSelector(
    (store) => store.customerLogin.customer_request,
  );
  const navigate = useNavigate();

  const downloadResourceDocsTravel = async (producCode) => {
    const res = await axe.get(`/travel/informativa/${producCode}/public`, {
      responseType: 'blob',
    });

    downloadFile(res.data);
  };

  const downloadResourceDocs = async (id, proffesion) => {
    const res = await axe.get(
      `/download-resource-document/${id}/${proffesion}/public`,
      {
        responseType: 'blob',
      },
    );

    downloadFile(res.data);
  };

  const getResCivileName = (productId) => {
    if ([1, 2, 3, 4, 10, 13].includes(productId)) {
      return 'Responsabilita Civile';
    }

    if (productId == 9) {
      return 'Tutela Legale';
    }

    if (productId == 11) {
      return 'Assistenza casa e famiglia';
    }

    if (productId == 12) {
      return 'Salute';
    }

    return '';
  };

  useEffect(() => {
    if (!is_auth) {
      navigate('/login');
    }
  }, [is_auth]);

  const fetchData = (token, clientId) => {
    getCustomerInfo(token, clientId, navigate);
  };

  useEffect(() => {
    if (requestToken && clientId) {
      fetchData(requestToken, clientId);
    }
  }, [requestToken, clientId]);

  if (loading || !request) {
    return <PageLoading />;
  }

  return (
    <>
      {postVenditaQuote !== null && (
        <ModalClientPage
          setPostVenditaQuote={setPostVenditaQuote}
          postVenditaQuote={postVenditaQuote}
          setSelectedQuote={setSelectedQuote}
          selectedQuote={selectedQuote}
          fetchData={fetchData}
          active_customer={active_customer}
        />
      )}
      <UploadNavbar className="d-flex align-items-center justify-content-between p-4">
        <Logo href="/">
          <img src={logoGreen} alt="Greenia SLR" width={150} />
        </Logo>
        <Link to="/login" className="btn btn-light">
          Esci
        </Link>
      </UploadNavbar>

      <div className="row container-fluid mx-auto mt-5">
        <div className="col-xl-12">
          <div className="row mb-2">
            <div className="col-12 col-md-6">
              <div className="card border mb-2">
                <div className="card-header bg-success text-white">
                  <h2 className="h3 mb-0 card-title">Anagrafica</h2>
                </div>
                <div className="card-body">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      {request.insurance_request.customer.gender === 'G' && (
                        <>
                          {' '}
                          <b>Compagnia:</b>
                          {' '}
                          {request.insurance_request.customer.business_name}
                        </>
                      )}
                      {request.insurance_request.customer.gender !== 'G' && (
                        <>
                          <b>Nome e Cognome:</b>
                          {' '}
                          {request.insurance_request.customer.name}
                          {' '}
                          {request.insurance_request.customer.surname}
                        </>
                      )}
                    </li>
                    <li className="list-group-item">
                      <b>Email:</b>
                      {' '}
                      {request.insurance_request.customer.email}
                    </li>
                    <li className="list-group-item">
                      <b>Telefono:</b>
                      {' '}
                      {request.insurance_request.customer.phone}
                    </li>
                    <li className="list-group-item">
                      <b>Indirizo:</b>
                      {' '}
                      {request.insurance_request.customer.address}
                      ,
                      {' '}
                      {request.insurance_request.customer.house_number}
                      ,
                      {' '}
                      {request.insurance_request.customer.postal_code}
                      ,
                      {' '}
                      {
                        request.insurance_request.customer.city_of_residence
                          .name
                      }
                    </li>

                    {request.insurance_request.customer.gender === 'G' ? (
                      <li className="list-group-item">
                        <b>Vat Number:</b>
                        {' '}
                        {request.insurance_request.customer.vat_number}
                      </li>
                    ) : (
                      <>
                        <li className="list-group-item">
                          <b>Codice Fiscale:</b>
                          {' '}
                          {request.insurance_request.customer.fiscal_code}
                        </li>
                        <li className="list-group-item">
                          <b>Data di Nascita:</b>
                          {' '}
                          {request.insurance_request.customer.date_of_birth}
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            {[1, 3, 13].includes(
              parseInt(request.insurance_request.product_id),
            ) && (
              <div className="col-12 col-md-6">
                <div className="card border">
                  <div className="card-header bg-success text-white">
                    <h2 className="h3 mb-0 card-title">Dati Veicolo</h2>
                  </div>
                  <div className="card-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <b>Targa:</b>
                        {' '}
                        {request.insurance_request.vehicle.vehicle_plate}
                      </li>
                      <li className="list-group-item">
                        <b>Marca:</b>
                        {' '}
                        {request.insurance_request.vehicle.brand.name}
                      </li>
                      <li className="list-group-item">
                        <b>Modello:</b>
                        {' '}
                        {request.insurance_request.vehicle.model.name}
                      </li>
                      <li className="list-group-item">
                        <b>Prima Immatricolazione:</b>
                        {' '}
                        {request.insurance_request.vehicle.vehicle_month}
                        /
                        {request.insurance_request.vehicle.vehicle_year}
                      </li>
                      <li className="list-group-item">
                        <b>Altro Alimentazione:</b>
                        {' '}
                        {request.insurance_request.vehicle.fuel.name}
                      </li>
                      <li className="list-group-item">
                        <b>Antifurto:</b>
                        {' '}
                        {
                          request.insurance_request.vehicle.theft_protection
                            .name
                        }
                      </li>
                      <li className="list-group-item">
                        <b>Auto Comprata:</b>
                        {' '}
                        {
                          request.insurance_request.vehicle
                            .vehicle_purchased_year
                        }
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}

            {request.insurance_request.product_id == 2 && (
              <div className="col-12 col-md-6">
                <div className="card border">
                  <div className="card-header bg-success text-white">
                    <h2 className="h3 mb-0 card-title">Dati Veicolo</h2>
                  </div>
                  <div className="card-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <b>Targa:</b>
                        {' '}
                        {request.insurance_request.moto.moto_plate}
                      </li>
                      <li className="list-group-item">
                        <b>Marca:</b>
                        {' '}
                        {request.insurance_request.moto.brand.name}
                      </li>
                      <li className="list-group-item">
                        <b>Modello:</b>
                        {' '}
                        {request.insurance_request.moto.model.name}
                      </li>
                      <li className="list-group-item">
                        <b>Prima Immatricolazione:</b>
                        {' '}
                        {request.insurance_request.moto.moto_month}
                        /
                        {request.insurance_request.moto.moto_year}
                      </li>
                      <li className="list-group-item">
                        <b>Antifurto:</b>
                        {' '}
                        {request.insurance_request.moto.theft_protection.name}
                      </li>
                      <li className="list-group-item">
                        <b>Moto Comprata:</b>
                        {' '}
                        {request.insurance_request.moto.moto_purchased_year}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}

            {request.insurance_request.product_id == 4 && (
              <div className="col-12 col-md-6">
                <div className="card border ">
                  <div className="card-header bg-success text-white">
                    <h2 className="h3 mb-0 card-title">Dati Proffessione</h2>
                  </div>
                  <div className="card-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <b>Billed:</b>
                        {' '}
                        {request.insurance_request.professional.billed}
                      </li>
                      <li className="list-group-item">
                        <b>Max Billed:</b>
                        {' '}
                        {request.insurance_request.professional.billed_maximum}
                      </li>
                      <li className="list-group-item">
                        <b>Profession:</b>
                        {' '}
                        {request.insurance_request.professional.profession_desc}
                      </li>
                      <li className="list-group-item">
                        <b>High Risk:</b>
                        {' '}
                        {request.insurance_request.professional.high_risk}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}

            {request.insurance_request.product_id == 12 && (
              <div className="col-12 col-md-6">
                <div className="card border ">
                  <div className="card-header bg-success text-white">
                    <h2 className="h3 mb-0 card-title">Dati Salute</h2>
                  </div>
                  <div className="card-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <b>Tipo Di lavoro:</b>
                        {' '}
                        {request.insurance_request.work_type
                        === 'attivita_non_manuali'
                          ? 'Attivita Non Manuali'
                          : 'Attivita Manuali'}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}

            {request.insurance_request.product_id == 11 && (
              <div className="col-12 col-md-6">
                <div className="card border ">
                  <div className="card-header bg-success text-white">
                    <h2 className="h3 mb-0 card-title">Dati Casa</h2>
                  </div>
                  <div className="card-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <b>Tipo Abitazione:</b>
                        {' '}
                        {
                          houseTypes.find(
                            (type) => type.id
                              === request.insurance_request.house.house_type,
                          ).name
                        }
                      </li>
                      <li className="list-group-item">
                        <b>Tipologia Propietario:</b>
                        {' '}
                        {
                          ownerTypes.find(
                            (type) => type.id
                              === request.insurance_request.house.owner_typology,
                          ).name
                        }
                      </li>
                      <li className="list-group-item">
                        <b>La superficie:</b>
                        {' '}
                        {request.insurance_request.house.square_meters}
                        {' '}
                        m
                        <sup>2</sup>
                      </li>
                      <li className="list-group-item">
                        <b>Provincia Abitazione:</b>
                        {' '}
                        {request.insurance_request.house.province.name}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}

            {request.insurance_request.product_id == 8 && (
              <div className="col-12 col-md-6">
                <div className="card border ">
                  <div className="card-header bg-success text-white">
                    <h2 className="h3 mb-0 card-title">Dati Viaggi</h2>
                  </div>
                  <div className="card-body">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <b>Destinazione:</b>
                        {' '}
                        {
                          request.insurance_request.insurance_request_travel
                            .country.name
                        }
                      </li>
                      <li className="list-group-item">
                        <b>Data Ritorno:</b>
                        {' '}
                        {moment(
                          request.insurance_request.insurance_request_travel
                            .end_date,
                        ).format('DD-MM-YYYY')}
                      </li>
                      <li className="list-group-item">
                        <b>Numero Persone Incluse:</b>
                        {' '}
                        {request.insurance_request.insurance_request_travel
                          .insurance_request_travel_people.length
                          + (request.insurance_request.insurance_request_travel
                            .is_contractor_included
                            ? 1
                            : 0)}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="col-12">
          <div className="card">
            <div className="card-header bg-success text-white">
              <h2 className="h3 mb-0 card-title">Polizze emesse</h2>
            </div>
            <div>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Compagnia</th>
                    <th scope="col">Rischio</th>
                    <th scope="col">Premio</th>
                    <th scope="col">Data Di Emmisione</th>
                    <th scope="col">Data Di Scadenza</th>
                    <th scope="col">Status</th>
                    <th scope="col">Post Vendita</th>
                  </tr>
                </thead>
                <tbody>
                  <TableRowStyle>
                    <td>
                      {request.rc_quotation.company !== null
                        ? request.rc_quotation.company.name
                        : request.rc_quotation.preventivas_company.company_name}
                    </td>
                    <td>{getResCivileName(request.rc_quotation.product_id)}</td>
                    <td>
                      &euro;
                      {request.rc_quotation.amount}
                    </td>
                    <td>
                      {moment(
                        request.rc_quotation.insurance_request
                          .policy_effective_date,
                      )
                        .toDate()
                        .toLocaleDateString('it-IT')}
                    </td>
                    <td>
                      {moment(
                        request.rc_quotation.insurance_request
                          .policy_effective_date,
                      )
                        .add(1, 'years')
                        .toDate()
                        .toLocaleDateString('it-IT')}
                    </td>
                    <td>{request.rc_quotation.quotation_status.name}</td>
                    <td>
                      <button
                        onClick={() => selectQuotePostVendita(request.rc_quotation)}
                        className="btn btn-success w-100"
                      >
                        Apri
                      </button>
                    </td>
                  </TableRowStyle>
                  {request.rc_quotation || request.risk_quotations !== 0
                    ? request.risk_quotations.map((quote, index) => (
                      <TableRowStyle key={index}>
                        <td>
                          {quote.company !== null
                            ? quote.company.name
                            : quote.preventivas_company.company_name}
                        </td>
                        <td>
                          {
                              quotationTypes.find(
                                (type) => type.id === quote.quote_type,
                              ).name
                            }
                        </td>
                        <td>
                          &euro;
                          {quote.amount}
                        </td>
                        <td>
                          {moment(
                            quote.insurance_request.policy_effective_date,
                          )
                            .toDate()
                            .toLocaleDateString('it-IT')}
                        </td>
                        <td>
                          {moment(
                            quote.insurance_request.policy_effective_date,
                          )
                            .add(1, 'years')
                            .toDate()
                            .toLocaleDateString('it-IT')}
                        </td>
                        <td>{quote.quotation_status.name}</td>
                        <td>
                          <button
                            onClick={() => selectQuotePostVendita(quote)}
                            className="btn btn-success w-100"
                          >
                            Apri
                          </button>
                        </td>
                      </TableRowStyle>
                    ))
                    : ''}

                  {request.rc_quotation.guarantees.length > 0
                  && request.rc_quotation.product_id == 2
                    ? request.rc_quotation.guarantees.map(
                      (guarantee, index) => (
                        <TableRowStyle key={index}>
                          <td>{request.rc_quotation.company.name}</td>
                          <td>
                            {
                                primaQuoteTypes.find(
                                  (type) => type.id === guarantee.guarantees_slug,
                                ).name
                              }
                          </td>
                          <td>
                            &euro;
                            {guarantee.amount}
                          </td>
                          <td>
                            {moment(request.rc_quotation.emission_date)
                              .toDate()
                              .toLocaleDateString('it-IT')}
                          </td>
                          <td>
                            {moment(request.rc_quotation.emission_date)
                              .add(1, 'years')
                              .toDate()
                              .toLocaleDateString('it-IT')}
                          </td>
                          <td>Poliza Emmesa</td>
                        </TableRowStyle>
                      ),
                    )
                    : ''}
                  {request.insurance_request.guarantees
                  && request.insurance_request.guarantees.length === 0
                  && request.risk_quotations.length === 0 ? (
                    <tr>
                      <td colSpan={5}>Nessuna Polizza Emessa Trovata!</td>
                    </tr>
                    ) : (
                      ''
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <ListPostVendita request={request} />
        <div className="col-6 mt-2">
          <div className="card">
            <div className="card-header bg-success text-white">
              <h2 className="h3 mb-0 card-title">Documenti</h2>
            </div>
            <div className="px-2">
              <div className="row p-2">
                {request.insurance_request.documents.map((document, index) => (
                  <UploadedFile
                    key={index}
                    doc={document}
                    activeCustomerId={active_customer.id}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 mt-2">
          <div className="card">
            <div className="card-header bg-success text-white">
              <h2 className="h3 mb-0 card-title">
                Informative Pre contrattuali
              </h2>
            </div>
            <div className="px-2">
              <div className="row p-2">
                {[1, 3].includes(
                  parseInt(request.insurance_request.product_id),
                ) && (
                  <>
                    <div className="col-12 p-1">
                      <div className="p-3 border rounded border-2 position-relative">
                        <div className="d-flex align-items-start justify-content-start flex-column gap-3">
                          <div className="d-flex align-items-center justify-content-start gap-3">
                            <FaFile className="fs-4 text-dark" />
                            <p className="m-0">
                              Informazioni Precontrattuali RCA
                            </p>
                          </div>
                          {request.insurance_request.product_id == 1 && (
                            <button
                              onClick={() => (request.rc_quotation.company_id === 1
                                || request.rc_quotation.company_id === 17
                                ? downloadResourceDocs(1, 0)
                                : request.rc_quotation.company_id == 2
                                  ? downloadResourceDocs(6, 0)
                                  : {})}
                              className="btn btn-success btn-sm px-3 py-1"
                            >
                              <FaDownload className="fs-6" />
                            </button>
                          )}
                          {request.insurance_request.product_id == 3 && (
                            <button
                              onClick={() => (request.rc_quotation.company_id === 1
                                || request.rc_quotation.company_id === 17
                                ? downloadResourceDocs(5, 0)
                                : request.rc_quotation.company_id == 2
                                  ? downloadResourceDocs(7, 0)
                                  : {})}
                              className="btn btn-success btn-sm px-3 py-1"
                            >
                              <FaDownload className="fs-6" />
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    {request.risk_quotations
                      && request.risk_quotations.find(
                        (q) => q.company_id === 16,
                      ) !== undefined && (
                        <div className="col-12 p-1">
                          <div className="p-3 border rounded border-2 position-relative">
                            <div className="d-flex align-items-start justify-content-start flex-column gap-3">
                              <div className="d-flex align-items-center justify-content-start gap-3">
                                <FaFile className="fs-4 text-dark" />
                                <p className="m-0">
                                  Informazioni Precontrattuali Global Assistance
                                </p>
                              </div>
                              <button
                                onClick={() => downloadResourceDocs(13, 0)}
                                className="btn btn-success btn-sm px-3 py-1"
                              >
                                <FaDownload className="fs-6" />
                              </button>
                            </div>
                          </div>
                        </div>
                    )}

                    {request.risk_quotations
                      && request.risk_quotations.find(
                        (q) => q.company_id === 8,
                      ) !== undefined && (
                        <div className="col-12 p-1">
                          <div className="p-3 border rounded border-2 position-relative">
                            <div className="d-flex align-items-start justify-content-start flex-column gap-3">
                              <div className="d-flex align-items-center justify-content-start gap-3">
                                <FaFile className="fs-4 text-dark" />
                                <p className="m-0">
                                  Informazioni Precontrattuali Europe Assistance
                                </p>
                              </div>
                              <button
                                onClick={() => downloadResourceDocs(14, 0)}
                                className="btn btn-success btn-sm px-3 py-1"
                              >
                                <FaDownload className="fs-6" />
                              </button>
                            </div>
                          </div>
                        </div>
                    )}
                  </>
                )}
                {request.insurance_request.product_id == 13 && (
                  <>
                    {request.risk_quotations
                      && request.risk_quotations.find(
                        (q) => q.company_id === 16,
                      ) !== undefined && (
                        <div className="col-12 p-1">
                          <div className="p-3 border rounded border-2 position-relative">
                            <div className="d-flex align-items-start justify-content-start flex-column gap-3">
                              <div className="d-flex align-items-center justify-content-start gap-3">
                                <FaFile className="fs-4 text-dark" />
                                <p className="m-0">
                                  Informazioni Precontrattuali Global Assistance
                                </p>
                              </div>
                              <button
                                onClick={() => downloadResourceDocs(13, 0)}
                                className="btn btn-success btn-sm px-3 py-1"
                              >
                                <FaDownload className="fs-6" />
                              </button>
                            </div>
                          </div>
                        </div>
                    )}

                    {request.risk_quotations
                      && request.risk_quotations.find(
                        (q) => q.company_id === 8,
                      ) !== undefined && (
                        <div className="col-12 p-1">
                          <div className="p-3 border rounded border-2 position-relative">
                            <div className="d-flex align-items-start justify-content-start flex-column gap-3">
                              <div className="d-flex align-items-center justify-content-start gap-3">
                                <FaFile className="fs-4 text-dark" />
                                <p className="m-0">
                                  Informazioni Precontrattuali Europe Assistance
                                </p>
                              </div>
                              <button
                                onClick={() => downloadResourceDocs(14, 0)}
                                className="btn btn-success btn-sm px-3 py-1"
                              >
                                <FaDownload className="fs-6" />
                              </button>
                            </div>
                          </div>
                        </div>
                    )}
                  </>
                )}

                {[4].includes(
                  parseInt(request.insurance_request.product_id),
                ) ? (
                  <>
                    {set_informativi[
                      request.insurance_request.professional.profession
                    ] !== '' && (
                      <>
                        <div className="col-12 p-1">
                          <div className="p-3 border rounded border-2 position-relative">
                            <div className="d-flex align-items-start justify-content-start flex-column gap-3">
                              <div className="d-flex align-items-center justify-content-start gap-3">
                                <FaFile className="fs-4 text-dark" />
                                <p className="m-0">
                                  Informazioni Precontrattuali
                                </p>
                              </div>
                              <button
                                onClick={() => downloadResourceDocs(
                                  8,
                                  request.insurance_request.professional
                                    .profession,
                                )}
                                className="btn btn-success btn-sm px-3 py-1"
                              >
                                <FaDownload className="fs-6" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 p-1">
                          <div className="p-3 border rounded border-2 position-relative">
                            <div className="d-flex align-items-start justify-content-start flex-column gap-3">
                              <div className="d-flex align-items-center justify-content-start gap-3">
                                <FaFile className="fs-4 text-dark" />
                                <p className="m-0">Questionario</p>
                              </div>
                              <button
                                onClick={() => downloadResourceDocs(
                                  4,
                                  request.insurance_request.professional
                                    .profession,
                                )}
                                className="btn btn-success btn-sm px-3 py-1"
                              >
                                <FaDownload className="fs-6" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                  ) : (
                    ''
                  )}

                {request.insurance_request.product_id == 11 && (
                  <>
                    <div className="col-12 p-1">
                      <div className="p-3 border rounded border-2 position-relative">
                        <div className="d-flex align-items-start justify-content-start flex-column gap-3">
                          <div className="d-flex align-items-center justify-content-start gap-3">
                            <FaFile className="fs-4 text-dark" />
                            <p className="m-0">
                              Informazioni Precontrattuali Casa
                            </p>
                          </div>
                          <button
                            onClick={() => downloadResourceDocs(15, 0)}
                            className="btn btn-success btn-sm px-3 py-1"
                          >
                            <FaDownload className="fs-6" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {request.insurance_request.product_id == 12 && (
                  <>
                    <div className="col-12 p-1">
                      <div className="p-3 border rounded border-2 position-relative">
                        <div className="d-flex align-items-start justify-content-start flex-column gap-3">
                          <div className="d-flex align-items-center justify-content-start gap-3">
                            <FaFile className="fs-4 text-dark" />
                            <p className="m-0">
                              Informazioni Precontrattuali Europe Assistance
                            </p>
                          </div>
                          <button
                            onClick={() => downloadResourceDocs(16, 0)}
                            className="btn btn-success btn-sm px-3 py-1"
                          >
                            <FaDownload className="fs-6" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {request.insurance_request.product_id == 8 && (
                  <>
                    <div className="col-12 p-1">
                      <div className="p-3 border rounded border-2 position-relative">
                        <div className="d-flex align-items-start justify-content-start flex-column gap-3">
                          <div className="d-flex align-items-center justify-content-start gap-3">
                            <FaFile className="fs-4 text-dark" />
                            <p className="m-0">
                              Informazioni Precontrattuali Europe Assistance
                            </p>
                          </div>
                          <button
                            onClick={() => downloadResourceDocsTravel(
                              request.rc_quotation
                                .quotation_data_travel_europeassistance
                                .product_code,
                            )}
                            className="btn btn-success btn-sm px-3 py-1"
                          >
                            <FaDownload className="fs-6" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <Footer />
      </div>
    </>
  );
}

export default ClientPage;
