import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  broker_documents: {
    error: null,
    loading: false,
    document_types: [],
    lead_documents: [],
  },
};

export const brokersSlice = createSlice({
  name: 'brokers',
  initialState,
  reducers: {
    brokerDocumentsPending: (state) => {
      state.broker_documents.loading = true;
      state.broker_documents.error = null;
    },
    brokerDocumentsRejected: (state, { payload }) => {
      state.broker_documents.loading = false;
      state.broker_documents.error = payload;
    },
    brokerDocumentsSuccess: (state, { payload }) => {
      state.broker_documents.loading = false;
      state.broker_documents.document_types = payload.data.document_types;
      state.broker_documents.lead_documents = payload.data.lead_documents;
    },

  },
});
