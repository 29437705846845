import axe from '../../services/axios-client/axiosClient';
import caller from './brokerCaller';

export const getBrokerDocuments = async (token) => {
  caller.brokerDocumentsPending();

  try {
    const response = await axe.get(`/intermediary-documents/${token}`);

    if (response.status !== 200) {
      caller.brokerDocumentsRejected('Error');
      return;
    }

    caller.brokerDocumentsSuccess(response.data);
  } catch (error) {
    caller.brokerDocumentsRejected('Error');
  }
};
