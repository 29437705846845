import axe from './axiosClient';

export const uploadBrokerDocument = (token, data) => axe.post(`/upload-lead-document/${token}`, data);
export const apiDownloadDocument = (documentId, active_customer_id) => axe.get(`/documents/${documentId}/download/${active_customer_id}`);
export const apiDownloadOptionalDocument = (documentId) => axe.get(`/optional-documents/${documentId}/download`);

export const downloadFile = (fileBlob) => {
  const file = window.URL.createObjectURL(fileBlob);
  window.open(file, '_blank');
};
