import { useState, useEffect } from 'react';
import Italian from '@uppy/locales/lib/it_IT';
import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/react';
import uploadIcon from '../../../assets/images/upload-icon.svg';
import { UppyContainer } from './Uploader.styles';

export function UploaderMultiple(props) {
  const { note, allowedFileTypes, onFileUpload } = props;

  const [uppy, setUppy] = useState();

  useEffect(() => {
    const uploadConfiguration = {
      locale: Italian,
      restrictions: {
        maxFileSize: 3000000, // 3MB per file
        maxNumberOfFiles: 10, // Allow up to 10 files
        minNumberOfFiles: 1,
        allowedFileTypes:
          allowedFileTypes !== undefined
            ? allowedFileTypes
            : ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'],
      },
    };

    const uppyInstance = new Uppy(uploadConfiguration);

    // Handle files when added
    uppyInstance.on('files-added', (files) => {
      const uploadedFiles = files.map((file) => file.data); // Get file data for all files
      onFileUpload(uploadedFiles); // Pass the list of files to the callback
    });

    setUppy(uppyInstance);
  }, []);

  if (!uppy) {
    return null;
  }

  return (
    <UppyContainer icon={uploadIcon}>
      <Dashboard uppy={uppy} note={note} proudlyDisplayPoweredByUppy={false} />
    </UppyContainer>
  );
}
