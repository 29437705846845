import styled from 'styled-components';
import { LightColor } from '../utils/StyleVariables';

export const UploadedDocumentWrapper = styled.div`
  height: 200px !important;
  max-width: 300px;
  background-color: #fff;
  border: 2px solid #dbdde6;
  border-radius: 15px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;

  img {
    margin-bottom: 10px;
    margin-top: 25px;
  }

  p {
    color: #d5d5d5;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
    cursor: pointer;
  }

  h3 {
    color: #99dbb9;
    font-family: Poppins;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
    margin-left: 5px;
  }

  @media (max-width: 1200px) {
    h3 {
      font-size: 14px;
      text-align: center;
      padding: 5px;
    }

    p {
      font-size: 12px;
      padding: 5px;
    }

    img {
      width: 35px;
    }
  }
`;

export const BadgeStatus = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 10px;
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: inline-block;
  padding: 6px 12px;

  @media (max-width: 1200px) {
    padding: 4px 8px;
    font-size: 10px;
    border-radius: 8px;
    top: 5px;
    right: 5px;
  }
`;

export const UploadNavbar = styled.div`
      background-color: #008944;
      padding: 10px 0px;
`;

export const DocumentUploaded = styled.div`
  padding: 125px 0;

  h1{
        font-family: "Tobi Pro", sans-serif;
        color: #008944;
        font-size: 30px;
        margin-bottom: 10px;
    }

  p{
      margin-top: 20px;
      color: #aaaaaa;
      font-size: 20px;
      font-family: "Tobi Pro", sans-serif;
      font-weight: lighter;
  }
 
`;

export const CheckMark = styled.div`
  color: ${LightColor};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;

  img {
    transition: all 200ms ease-in-out;
  }

  img:hover {
    transform: scale(1.05);
  }

  @media (max-width: 550px) {
    img {
      width: 150px;
    }
  }
`;
