import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router';
import { ModalBody, ModalFooter } from '../../pages/ClientPage.styles';
import { reasonForReplacement } from '../../common/utils/constants';
import axe from '../../services/axios-client/axiosClient';
import { Uploader } from '../../common/components/uploader/Uploader';
import { generalAlertError } from '../../common/utils/alerts';

const asInputOptions = {
  shouldDirty: true,
  shouldTouch: true,
  shouldValidate: true,
};

function ReplacementForm({
  selectedQuote,
  closeAll,
  fetchData,
  active_customer,
}) {
  const { requestToken, clientId } = useParams();
  const [documentTypes, setDocumentTypes] = useState([]);
  const [loadingTypes, setLoadingTypes] = useState(true);
  const [sendingData, setSendingData] = useState(false);

  const lazyFunction = () => {
    if (selectedQuote !== null) {
      if ([1, 3].includes(selectedQuote.product_id)) {
        return yup
          .string()
          .required('Il campo è obbligatorio.')
          .matches(/^[A-Z]{2}[0-9]{3}[A-Z]{2} *$/, 'La targa non e coretta');
      }

      if ([2].includes(selectedQuote.product_id)) {
        return yup
          .string()
          .required('Il campo è obbligatorio.')
          .matches(/^[A-Z]{2}[0-9]{5} *$/, 'La targa non e coretta');
      }
    }

    return yup.string().nullable();
  };

  const schema = yup.object({
    files: yup.array().required('Documenti sono obbligatori'),
    note: yup.string().required('Il campo è obbligatorio'),
    replacement_reason: yup.string().required('Il campo è obbligatorio'),
    plate: yup.lazy(lazyFunction),
    active_customer_id: yup.number().required('Id Cliente e obligatoria!'),
  });

  const {
    register,
    formState: { errors, touchedFields },
    setValue,
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
    values: {
      files: [],
      note: '',
      replacement_reason: '',
      plate: '',
      active_customer_id: active_customer.id,
    },
  });

  const formData = watch();

  const createNewReplacement = async () => {
    setSendingData(true);
    const response = await axe.post(
      `/post-vendita/replacement/create/${selectedQuote.id}`,
      formData,
    );

    if (response.status !== 200) {
      generalAlertError('Errore');
      console.log('Error', response);
      setSendingData(false);
      return;
    }

    fetchData(requestToken, clientId);
    closeAll();
    setSendingData(false);
  };

  const getTypes = async () => {
    setLoadingTypes(true);
    const response = await axe.get('/after-sale-types/replacement');
    setDocumentTypes(response.data.data.document_types);
    setLoadingTypes(false);
  };

  const fileInputHandler = (file, type_id) => {
    let baseURL = '';
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const fileType = file.type;
    reader.onload = () => {
      baseURL = reader.result;
      setValue(
        'files',
        [
          ...formData.files,
          {
            original_filename: file.name,
            content: baseURL.replace(`data:${fileType};base64,`, ''),
            type: `${fileType},`,
            type_id,
          },
        ],
        asInputOptions,
      );
    };
  };

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }
    if (touchedFields[name] === undefined) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    getTypes();
  }, []);

  return (
    <>
      <ModalBody className="p-4">
        <form>
          {[1, 2, 3].includes(selectedQuote.product_id) && (
            <div className="mb-3">
              <label htmlFor="plate" className="form-label">
                Targa dell' auto
              </label>
              <input
                type="text"
                {...register('plate')}
                className={`form-control ${
                  isValid('plate') ? 'is-valid' : ''
                } ${errors.plate?.message ? 'is-invalid' : ''}`}
              />
              <div className="invalid-feedback">{errors.plate?.message}</div>
            </div>
          )}
          <div className="mb-3">
            <label htmlFor="replacement_reason" className="form-label">
              Motivo della sostituzione
            </label>
            <select
              name="replacement_reason"
              className={`form-select ${
                isValid('replacement_reason') ? 'is-valid' : ''
              } ${errors.replacement_reason?.message ? 'is-invalid' : ''}`}
              {...register('replacement_reason')}
            >
              <option value="" disabled>
                Seleziona Motivo
              </option>
              {reasonForReplacement.map((item, index) => (
                <option value={item.id} key={index}>
                  {item.name}
                </option>
              ))}
            </select>
            <div className="invalid-feedback">
              {errors.replacement_reason?.message}
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="nota" className="form-label">
              Descrivi cosa hai bisogno di modificare
            </label>
            <textarea
              {...register('note')}
              className={`form-control ${isValid('note') ? 'is-valid' : ''} ${
                errors.note?.message ? 'is-invalid' : ''
              }`}
              rows="3"
            />
            <div className="invalid-feedback">{errors.note?.message}</div>
          </div>
          <div className="mb-3">
            <div className="row">
              {!loadingTypes
                && documentTypes.map((type, index) => (
                  <div className="col-6" key={index}>
                    <Uploader
                      note={type.name}
                      onFileUpload={(file) => fileInputHandler(file, type.id)}
                    />
                  </div>
                ))}
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter className="p-3">
        <div className="d-flex align-items-center justify-content-end">
          <button
            disabled={sendingData}
            onClick={() => createNewReplacement()}
            className="btn btn-success px-5 py-2"
          >
            Crea
          </button>
        </div>
      </ModalFooter>
    </>
  );
}

export default ReplacementForm;
