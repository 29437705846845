import styled from 'styled-components';

export const LoginStyles = styled.div`
    padding-top:150px ;
    padding-bottom:150px ;

`;

export const LoginCard = styled.div`
   width: 600px;
   padding: 30px;

   .error-text{
    color: red;
    font-size: 12px;
   }

   .valid-text{
    border-color: red;
   }

`;
