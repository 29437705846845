import React from 'react';
import AccidentsList from './PostVenditaTables/AccidentsList';
import ReplacementsList from './PostVenditaTables/ReplacementsList';
import CancellationList from './PostVenditaTables/CancellationList';
import ModificationsList from './PostVenditaTables/ModificationsList';

function ListPostVendita({ request }) {
  return (
    <div className="col-12 mt-2">
      <div className="row g-1">
        <AccidentsList accidents={request.accidents} />
        <ReplacementsList replacements={request.replacements} />
        <CancellationList cancellations={request.cancellations} />
        <ModificationsList modifications={request.modifications} />
      </div>
    </div>
  );
}

export default ListPostVendita;
