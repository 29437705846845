import styled from 'styled-components';
import { PrimaryColor } from '../utils/StyleVariables';

export const Container = styled.div`
  background-color: ${PrimaryColor};
  padding-top: 30px;

  input{
    box-shadow: 0 6px 5px 0 rgba(198 , 198 , 198, 0.19);

    &.invalid {
        border: 1px solid #B9150A;
        box-shadow: 0px 0px 5px 0px #B9150A;
      }

    
  }
  button{
    box-shadow: 0 6px 5px 0 rgba(198 , 198 , 198, 0.19);
    color: ${PrimaryColor};

    &:hover{
      border: 1px solid white;
      background-color: ${PrimaryColor};
    color: white;
    }
  }

  label{
    color: white;
    font-size: 14px;
  }
  
  form span {
    color: #B9150A;
    font-size: 15px;
  }



  .accept-terms{
    @media screen and (max-width: 380px) {
    font-size: 14px;
  }
  @media (min-width: 381px) and (max-width: 600px) {
    font-size: 15px;
  }
  }

`;

export const WorkWithUsHeader = styled.h1`
  font-family: 'Merriweather';
  font-weight: 600;
  color: white;
  font-size: 50px;
  margin-bottom: 20px;


  @media screen and (max-width: 380px) {
    font-size: 35px;
  }
  @media (min-width: 381px) and (max-width: 600px) {
    font-size: 45px;
  }
`;

export const WorkWithUsParagraph = styled.p`
  font-family: 'Poppins';
  color: white;
  font-size: 20px;

  @media screen and (max-width: 380px) {
    font-size: 16px;
  }
  @media (min-width: 381px) and (max-width: 600px) {
    font-size: 18px;
  }
`;

export const FrequentlyQuestions = styled.div`
  h3 {
    font-family: "Tobi Pro", sans-serif;
    font-size: 36px;
    color: ${PrimaryColor};
  }
`;
