import styled from 'styled-components';

export const TableRowStyle = styled.tr`
 
`;

export const ModalClientPostVendita = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255,255,255,0.7);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ModalBox = styled.div`
    background: #ffffff;
    max-width: 800px;
    width: 100%;
    max-height: 600px;
    height: 100%;
`;
export const ModalHeader = styled.div`
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
`;

export const ModalBody = styled.div`
  overflow: auto;
  max-height: 450px;
`;

export const ModalFooter = styled.div`
  max-height: 100px;
  height: 100%;
`;

export const MapContainerStyles = styled.div`
  #map {
    height: 250px;
    width: 100%;
  }

  .pac-container {
    z-index: 10000;
  }
`;
