import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Uploader } from '../common/components/uploader/Uploader';
import {
  BadgeStatus, CheckMark, DocumentUploaded, UploadedDocumentWrapper, UploadNavbar,
} from './UploadBrokerDocuments.styles';
import pdfUploaded from '../assets/images/file.svg';
import { uploadBrokerDocument } from '../services/axios-client/axeCommons';
import { getBrokerDocuments } from '../features/brokers/brokerActions';
import { generalAlertSuccessToast } from '../common/utils/alerts';
import PageLoading from '../common/components/elements/PageLoading';
import Footer from '../components/Footer';
import logoGreen from '../assets/images/logo-white.svg';
import checkMark from '../assets/images/check-mark.png';

import { Logo } from '../components/Navbar.styles';

function UploadBrokerDocuments() {
  const { loading, document_types, lead_documents } = useSelector((store) => store.brokers.broker_documents);
  const { token } = useParams();

  const updloadDocument = (file, typeId) => {
    let fileInfo;
    let baseURL = '';
    const uploadedFile = file;
    // Make new FileReader
    const reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsDataURL(uploadedFile);
    // on reader load pdf...
    reader.onload = async () => {
      fileInfo = uploadedFile.name;
      const fileType = uploadedFile.type;
      baseURL = reader.result;
      const data = {
        document_type_id: typeId,
        original_filename: fileInfo,
        unsigned_document: baseURL.replace(`data:${fileType};base64,`, ''),
      };

      const response = await uploadBrokerDocument(token, data);
      if (response.status !== 200) {
        return;
      }
      getBrokerDocuments(token);
      generalAlertSuccessToast('File caricato');
    };
  };

  useEffect(() => {
    getBrokerDocuments(token);
  }, [token]);

  if (loading) {
    <PageLoading />;
  }
  return (
    <div>
      <UploadNavbar>
        <Logo href="/">
          <img src={logoGreen} alt="Greenia SLR" width={150} />
        </Logo>
      </UploadNavbar>

      <div className="container mb-5">
        <div className="row">
          <div className="col-12">

            {document_types.length === lead_documents.length ? (
              <DocumentUploaded>
                <CheckMark>
                  <img src={checkMark} alt="Uploaded" width={150} />
                </CheckMark>
                <h1 className="text-center">Grazie per i documenti caricati </h1>
                <p className="text-center">Sarai contatto a breve</p>
              </DocumentUploaded>
            )
              : (
                <>
                  <h2 className="h2 text-center text-success mt-5">
                    Carica i tuoi documenti
                  </h2>
                  <div className="d-flex gap-3 flex-wrap justify-content-center align-items-center mt-5">
                    {document_types.map((document, index) => (
                      <React.Fragment key={index}>
                        {lead_documents.find((doc) => doc.document_type_id === document.id) === undefined ? <Uploader onFileUpload={(file) => updloadDocument(file, document.id)} note={document.name} /> : (
                          <UploadedDocumentWrapper className="w-full" style={{ minWidth: '300px' }}>
                            <BadgeStatus background="#99DBB9" color="#009232">
                              Caricato
                            </BadgeStatus>
                            <img src={pdfUploaded} alt="" width={50} />
                            {(lead_documents.find((doc) => doc.document_type_id === document.id).motivation !== null) && (
                              <p className="text-danger">
                                {lead_documents.find((doc) => doc.document_type_id === document.id).motivation}
                              </p>
                            )}
                            <p>
                              Documento Caricato
                            </p>
                            <h3>
                              {lead_documents.find((doc) => doc.document_type_id === document.id).document_type.name.toUpperCase()}
                            </h3>
                          </UploadedDocumentWrapper>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </>
              )}

          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default UploadBrokerDocuments;
