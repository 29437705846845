import React from 'react';
import moment from 'moment';
import { TableRowStyle } from '../../../pages/ClientPage.styles';

function ModificationsList({ modifications }) {
  return (
    <div className="col-6">
      <div className="card">
        <div className="card-header bg-success text-white">
          <h2 className="h3 mb-0 card-title">Modifica dati non tarifari</h2>
        </div>
        <div>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Nota</th>
                <th scope="col">Stato</th>
                <th scope="col">Data Creazione</th>
              </tr>
            </thead>
            <tbody>
              <TableRowStyle>
                {modifications.length !== 0 ? (
                  modifications.map((modification, index) => (
                    <React.Fragment key={index}>
                      <td>{modification.note}</td>
                      <td>{modification.status === 1 ? 'Aperto' : 'Chiuso'}</td>
                      <td>
                        {moment(modification.created_at).format('DD-MM-YYYY')}
                      </td>
                    </React.Fragment>
                  ))
                ) : (
                  <td colSpan={3}>Nessuna Modifica Trovata</td>
                )}
              </TableRowStyle>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ModificationsList;
