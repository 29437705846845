import { generalAlertError, generalAlertSuccessToast } from '../../common/utils/alerts';
import axe from '../../services/axios-client/axiosClient';
import caller from './customerLoginCaller';

export const loginActiveCustomer = async (formData, navigate) => {
  caller.loginCustomerPending();
  try {
    const response = await axe.post('/loginCustomer', formData);

    if (response.status !== 200) {
      generalAlertError('Errore Interno');
      caller.loginCustomerRejected('Errore Interno');
      return;
    }

    generalAlertSuccessToast('You Have Successfully Logged In');
    caller.loginCustomerSuccess(response.data);
    navigate(`/cliente/${response.data.data.request_token}/${response.data.data.active_customer.id}`);
  } catch (err) {
    caller.loginCustomerRejected('Errore Interno');
    generalAlertError('Errore Interno');
  }
};

export const getCustomerInfo = async (requestToken, activeCustomerId, navigate) => {
  caller.getRequestClientPending();
  try {
    const response = await axe.get(`/insurance-request-client/${requestToken}/${activeCustomerId}`);

    if (response.status !== 200) {
      caller.getRequestClientRejected('Errore Interno');
      navigate('/login');
      return;
    }

    caller.getRequestClientSuccess(response.data);
  } catch (err) {
    caller.getRequestClientRejected('Errore Interno');
    navigate('/login');
  }
};
