import React, { useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router';
import { UploaderMultiple } from '../../common/components/uploader/UploaderMultiple';
import {
  MapContainerStyles,
  ModalBody,
  ModalFooter,
} from '../../pages/ClientPage.styles';
import axe from '../../services/axios-client/axiosClient';
import { generalAlertError } from '../../common/utils/alerts';
import MapContainer from '../../common/MapContainer';

const schema = yup.object({
  files: yup.array().min(1, 'Devi caricare almeno 1 file'),
  note: yup.string().required('Il campo è obbligatorio'),
  address: yup.string().required('Il campo è obbligatorio').max(191),
  active_customer_id: yup.number().required('Id Cliente e obligatoria!'),
});

const asInputOptions = {
  shouldDirty: true,
  shouldTouch: true,
  shouldValidate: true,
};

function ReportAccident({
  postVenditaQuote,
  closeAll,
  fetchData,
  active_customer,
}) {
  const { requestToken, clientId } = useParams();
  const {
    register,
    formState: { errors, touchedFields },
    getValues,
    setValue,
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
    values: {
      files: [],
      note: '',
      address: '',
      latitude: 41.902782,
      longitude: 12.496366,
      active_customer_id: active_customer.id,
    },
  });
  const [sendingData, setSendingData] = useState(false);
  const answers = watch();

  const fileInputHandler = (files) => {
    const formData = getValues();

    if (files.length > 10 - formData.files.length) {
      generalAlertError('Non puoi caricare piu di 10 documenti');
      return;
    }

    const filesUpdated = [...formData.files];

    let files_counter = 0;

    Object.values(files).forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        filesUpdated.push({
          file_type: `${reader.result.split(';base64')[0].split('data:')[1]},`,
          original_filename: file.name,
          content: reader.result.split(';base64,')[1],
        });
        files_counter++;
        if (files_counter === files.length) {
          setValue('files', filesUpdated, asInputOptions);
        }
      };
    });
  };

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }
    if (touchedFields[name] === undefined) {
      return false;
    }
    return true;
  };

  const submitAnswers = async () => {
    setSendingData(true);
    const response = await axe.post(
      `/post-vendita/accident/create/${postVenditaQuote}`,
      answers,
    );

    if (response.status !== 200) {
      generalAlertError('Errore');
      console.log('Error', response);
      setSendingData(false);
      return;
    }

    fetchData(requestToken, clientId);
    closeAll();
    setSendingData(false);
  };

  return (
    <>
      <ModalBody className="p-4">
        <form>
          <div className="mb-3">
            <UploaderMultiple
              note="Carica documenti max. 10."
              onFileUpload={(files) => fileInputHandler(files)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="nota" className="form-label">
              Nota Denuncia
            </label>
            <textarea
              {...register('note')}
              className={`form-control ${isValid('note') ? 'is-valid' : ''} ${
                errors.note?.message ? 'is-invalid' : ''
              }`}
              rows="3"
            />
            <div className="invalid-feedback">{errors.note?.message}</div>
          </div>
          <div className="mb-3">
            <MapContainerStyles className="mt-2">
              <MapContainer
                error={errors.address?.message}
                registration={register}
                setValue={setValue}
                addressKeyName="address"
                lat={answers.latitude}
                lng={answers.longitude}
                latKeyName="latitude"
                lngKeyName="longitude"
              />
            </MapContainerStyles>
          </div>
        </form>
      </ModalBody>
      <ModalFooter className="p-3">
        <div className="d-flex align-items-center justify-content-end">
          <button
            disabled={sendingData}
            onClick={() => submitAnswers()}
            className="btn btn-success px-5 py-2"
          >
            Crea
          </button>
        </div>
      </ModalFooter>
    </>
  );
}

export default ReportAccident;
